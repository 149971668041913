<!-- MODE -->
<div class="d-flex">
  <div
    class="flex-10 d-flex align-self-stretch align-items-center circlewrapper"
  >
    <div class="flight_icon">
      <i class="fas {{ commuteIcon }} route-icon secondary-text-color"></i>
    </div>
  </div>
  <div class="flex-90 margintopbottom">
    <span>{{ duration }} </span>
  </div>
</div>
