import {
  Component,
  inject,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import {
  GoogleMap,
  GoogleMapsModule,
  MapAdvancedMarker,
  MapInfoWindow,
} from '@angular/google-maps';
@Component({
  selector: 'day-map',
  standalone: true,
  imports: [CommonModule, GoogleMapsModule],
  templateUrl: './day-map.component.html',
  styleUrls: ['./day-map.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DayMapComponent implements OnInit {
  activeModal = inject(NgbActiveModal);

  @Input() options: any;
  @ViewChild(GoogleMap, { static: false }) map!: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) info?: MapInfoWindow;

  mapInfoContent: string = '';
  dayNumber: string = '';
  markers: any = [];
  dayTrip: any;
  zoom = 12;
  mapOptions: google.maps.MapOptions = {
    mapId: '1ef0f91c91f89e23',
    zoomControl: true,
    scrollwheel: false,
    disableDoubleClickZoom: true,
  };
  pinElement: any;
  constructor() {}
  ngOnInit(): void {
    console.log('this.options');
    console.log(this.options);
    if (this.options.hasOwnProperty('dayNumber')) {
      this.dayNumber = this.options['dayNumber'];
    }

    this.dayTrip = this.options['dayTrip'] || [];
    console.log('this.dayTrip');
    console.log(this.dayTrip);
    this.createMarkers();
    console.log('this.maps');
  }

  createMarkerElement = (label: string) => {
    const div = document.createElement('div');
    div.className = 'map-marker';
    div.innerHTML = `<div class="map-marker-content">${label}</div>`;
    console.log('returning div', label);
    return div;
  };

  createMarkers() {
    this.markers = [];
    let count = 1;

    this.dayTrip['days'].forEach((place: any) => {
      console.log('place');
      console.log(place);
      if (place['type'] == 'place') {
        let lat: number = 0;
        let lng: number = 0;
        if (place.hasOwnProperty('parent')) {
          lat = parseFloat(place['parent']['lacation_address']['lat']);
          lng = parseFloat(place['parent']['lacation_address']['lan']);
        } else if (place['locationString']) {
          const latlng = place['locationString'].split(',');
          lat = latlng[0];
          lng = latlng[1];
        }
        if (lat != 0 && lng != 0) {
          console.log('lat, lng', lat, '--', lng);
          console.log(count);
          this.markers.push({
            position: new google.maps.LatLng(lat, lng),
            title: place['name'],
            options: {},
            content: this.createMarkerElement(count.toString()),
          });
          count++;
        }
      }
    });
    console.log('================================');
    setTimeout(() => {
      this.centerMap();
    }, 1000);
  }

  centerMap() {
    console.log('CENTER MAP');
    console.log(this.markers);
    const bounds = new google.maps.LatLngBounds();
    for (const marker of this.markers) {
      bounds.extend(marker.position);
    }
    this.map.googleMap?.fitBounds(bounds);
  }

  openInfoWindow(marker: MapAdvancedMarker, content: any) {
    this.mapInfoContent = content;
    this.info?.open(marker);
  }
}
