import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { OnInit } from '@angular/core';

import { CookieService } from 'ngx-cookie-service';
import { isPlatformBrowser } from '@angular/common';
/**
 * Cookie Service
 */
@Injectable({
  providedIn: 'root',
})
export class CookieServices implements OnInit {
  cookieValue = 'default';

  constructor(
    private cookieService: CookieService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {
    // set Cookie
    // Syntax- set( name: string, value: string, expires?: number | Date, path?: string, domain?: string, secure?: boolean ): void;
    //this.cookieService.set('appCookie', 'This is hello apps.');
  }

  // set Cookie
  /**
   * To set Cookies
   */
  setCookie(key: string, keyval: any) {
    if (keyval === null) {
      keyval = keyval;
    } else {
      keyval = JSON.stringify(keyval);
    }
    // console.log("in cookie set");
    // console.log(key);
    // console.log(keyval);
    const options = { path: '/' };
    this.cookieService.set(key, keyval, options);
  }
  /**
   * To set cookie with domain
   */
  setCookiedomain(key: string, keyval: any, domain: any) {
    if (keyval === null) {
      keyval = keyval;
    } else {
      keyval = JSON.stringify(keyval);
    }
    // console.log("in cookie set");
    // console.log(key);
    // console.log(keyval);
    const options = { path: '/', domain: domain };
    this.cookieService.set(key, keyval, options);
  }
  // Syntax - get( name: string ): string;
  /**
   *To get all cookies or a particular cookie
   */
  getCookie(key: string) {
    let dataObj: Object;
    if (isPlatformBrowser(this.platformId)) {
      let data: any;

      const check = this.cookieService.get(key);

      if (key === 'all') {
        data = this.cookieService.getAll();
      } else {
        data = this.cookieService.get(key);
      }
      // console.log(key);
      // console.log("before sending cokkie===");
      // console.log(data);
      const regex = /^(""|)$/;
      if (regex.test(data)) {
        dataObj = false;
      } else {
        if (data !== null && data !== '' && data !== undefined) {
          dataObj = JSON.parse(data);
        } else {
          dataObj = false;
        }
      }
    } else {
      dataObj = false;
    }

    return dataObj;
  }

  // check Cookie
  // Syntax - check( name: string ): boolean;
  /**
   * To check cookie whether it is defined or not
   */
  checkCookie(key: string) {
    let result;
    const check = this.getCookie(key);
    if (check !== false) {
      if (check === null) {
        result = false;
      } else {
        result = true;
      }
    } else {
      result = false;
    }
    return result;
  }

  // delete cookie
  // Syntax - delete( name: string, path?: string, domain?: string ): void;
  /**
   * To delete all cookies or a particular cookie
   */
  deleteCookie(key: string) {
    // console.log("deleting cookie" + key);
    if (key !== null) {
      this.setCookie(key, null);
    }
    // console.log("request to delete " + key);
    if (key !== 'all') {
      return this.cookieService.delete(key);
    } else {
      return this.cookieService.deleteAll();
    }
  }
}
