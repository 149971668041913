<div class="mt-0 mb-4 w-100 d-flex justify-content-start flex-wrap">
  <div class="d-inline-flex">
    <div class="content-body mr-3">
      <i class="fa fa-map-marker-alt"></i>
    </div>
    <div class="d-flex w-100 align-items-center small-heading">
      <span class="mr-3">Paris</span>
      <span> Arrival on 28/10/2023 at 13:19 </span>
    </div>
  </div>
</div>
