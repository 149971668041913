import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageComponent } from '../image/image.component';
import { GeneralService } from '../../../core/general.service';
import { RouterModule } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'day-places',
  standalone: true,
  imports: [CommonModule, ImageComponent, RouterModule,NgbTooltipModule],
  templateUrl: './day-places.component.html',
  styleUrls: ['./day-places.component.scss'],
})
export class DayPlacesComponent implements OnInit {
  @Input() place: any;
  @Input() tripUrl: any;
  arrivalTime: any;
  arrivalDateTime: any;
  stayDuration: string = '';
  hub: string = '';
  placeHighlight: SafeHtml = '';
  locationString: string = '';
  image: string = '';
  category: string = '';
  categoryIcon: string = '';
  placeUrl: string = '';
  rating: any = [];
  reason: string = '';
  place_address: string = '';
  timezone: string = '';
  tips: any = [];
  showTips: boolean=false;
  constructor(
    private generalService: GeneralService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.checks();
  }

  checks() {
    const categoryMaping: any = {
      h: { name: 'hotel', icon: 'fa-hotel' },
      p: { name: 'points-of-interest', icon: 'fa-map-marker-alt' },
      r: { name: 'restaurant', icon: 'fa-utensils' },
      a: { name: 'activity', icon: 'fa-hiking' },
      t: { name: 'hub', icon: 'fa-map-marked' },
      hc: { name: 'hub', icon: 'fa-map-marked' },
      fl: { name: 'airport', icon: 'fa-map-marked' },
      ts: { name: 'airport', icon: 'fa-map-marked' },
      s: { name: 'shopping', icon: 'fa-shopping-bag' },
    };
    this.arrivalTime = this.place.hasOwnProperty('arrival_time')
      ? this.place['arrival_time']
      : '';
    this.arrivalDateTime = this.place.hasOwnProperty('arrival_date_time')
      ? this.place['arrival_date_time']
      : '';

    const stayDuration =
      this.place.hasOwnProperty('duration') && this.place['duration']
        ? this.place['duration']
        : 0;
    if (stayDuration) {
      this.stayDuration = this.secondsToHHMM(stayDuration);
    }
    this.hub = this.place.hasOwnProperty('hub') ? this.place['hub'] : '';
    this.locationString = this.place.hasOwnProperty('locationString')
      ? this.place['locationString']
      : '';

    if (this.place.hasOwnProperty('parent')) {
      if (this.place['parent'].hasOwnProperty('experience_media')) {
        const placeImages = this.place['parent']['experience_media'];
        let imgRate = 0;
        let imgPath: string = '';
        placeImages.forEach((img: any) => {
          if (img['active']) {
            if (img['imgRate'] > imgRate) {
              imgRate = img['imgRate'];
              imgPath = img['path'];
            }
          }
        });
        this.image = imgPath;
      }
    }else if (this.place.hasOwnProperty('image')) {
      this.image = this.place['image'];
    }

    if (
      this.place.hasOwnProperty('place_type') &&
      categoryMaping[this.place['place_type']]
    ) {
      this.category = categoryMaping[this.place['place_type']].name;
      this.categoryIcon = categoryMaping[this.place['place_type']].icon;
    } else {
      this.category = 'other';
      this.categoryIcon = 'fa-map-marker-alt';
    }
    this.placeHighlight = this.place.hasOwnProperty('description')
      ? this.stripHtml(this.place['description'])
      : '';

    const rating = this.place.hasOwnProperty('rating')
      ? this.place['rating']
      : 0;
    this.tips = this.place.hasOwnProperty('tips') ? this.place['tips']:[] ;

    this.rating = Array(Math.ceil(rating)).fill(0);
    this.placeUrl =
      this.place.hasOwnProperty('parentURL') && this.category !== 'hotel'
        ? this.place['parentURL']
        : '';

    this.reason = this.place.hasOwnProperty('reason')
      ? this.place['reason'].replace(/['"]+/g, '')
      : '';
    //if(['hotel','points-of-interest','restaurant','activity','shopping'].includes(this.category)){
      this.place_address = this.place.hasOwnProperty('place_address')
      ? this.place['place_address'].replace(/['"]+/g, '')
      : '';
    //}

    this.timezone = this.place.hasOwnProperty('timezone')
      ? this.place['timezone']
      : '';
  }

  secondsToHHMM(seconds: number): string {
    const hours: number = Math.floor(seconds / 3600);
    const minutes: number = Math.floor((seconds % 3600) / 60);
    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')} hrs`;
  }

  getPlaceDetails() {
    const req = {
      requestMessage: 'identifyScrap',
      requestId: '888',
      apiKey: '',
      requestData: [
        {
          name: this.place['name'],
          coordinates: this.locationString,
          hub: this.hub,
        },
      ],
    };
    const url = 'trip/identifyScraps/' + this.tripUrl;
    this.generalService.postMethod(url, req).subscribe(
      (value: any) => {
        console.log(value);
      },
      (error: any) => {
        console.log('there is an error in this');
        console.log(error);
      }
    );
  }
  stripHtml(html: string) {
    console.log(' stripHtml');
    console.log(html);

    if (html)
      return this.sanitizer.bypassSecurityTrustHtml(
        html.replace(/<[^>]*>/g, '')
      );
    else return '';
  }
}
