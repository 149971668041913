<div class="modal-header">
  <h4 class="modal-title content-heading">Day {{ dayNumber }}</h4>
</div>
<div class="modal-body">
  <google-map
    #map
    height="100%"
    width="100%"
    [zoom]="zoom"
    [options]="mapOptions"
  >
    <map-advanced-marker
      #markerElem="mapAdvancedMarker"
      *ngFor="let marker of markers"
      [position]="marker.position"
      [title]="marker.title"
      [options]="marker.options"
      [content]="marker.content"
      (mapClick)="openInfoWindow(markerElem, marker.title)"
    ></map-advanced-marker>

    <map-info-window>
      <div class="custom-info-window">{{ mapInfoContent }}</div>
    </map-info-window>
  </google-map>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn secondary-button"
    (click)="activeModal.close('Close click')"
  >
    Close
  </button>
</div>
