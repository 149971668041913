<div
  class="d-flex align-items-stretch justify-content-between c-wrapperbox"
  *ngIf="validMode"
>
  <div class="transit_icon">
    
      <i  class="fa commute_icon" [ngClass]="commuteIcon"></i>
    
    <div class="extra-small-heading text-uppercase">
      {{ commuteType }}
    </div>
  </div>
  <div class="commute_details align-items-center">
    {{transitText}}
  </div>
  <div class="local_commute_options p-3" *ngIf="direction !== ''">
    
      <a [href]="direction" target="_blank">
        <i class="fa fa-directions primary-text-color"></i>
      </a>
  </div>
</div>
