export enum NotificationEvent {
  TripCreation = 'tripCreation',
  ItineraryGenerationCompleted = 'itineraryGenerationCompleted',
  ItineraryLoaded = 'itineraryLoaded',
  TripDeleted='tripDeleted',
  TripNotFound = 'tripNotFound',
  EditFailure = 'editFailure',
  LoadFailure = 'loadFailure',
  NotAuthorized = 'notAuthorized',
  TripUpdate = 'tripUpdate',
}