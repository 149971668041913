import { createCustomElement } from '@angular/elements';
import { createApplication } from '@angular/platform-browser';
import { ItineraryComponent } from './app/web-components/itinerary/itinerary.component';
import { appConfig } from './app/app.config';
(async () => {
  const app = await createApplication(appConfig);

  const customElement = createCustomElement(ItineraryComponent, {
    injector: app.injector,
  });

  customElements.define('tripsica-itinerary', customElement);
})();
