<div class="itinerary_type free_time">
  <div class="dur-distance mb-4">
    <span class="icon-min-width">
      <i class="fas fa-cocktail mr-3 new_secondarycolor2"></i>
    </span>
    <span class="small-heading gark-dark mr-1">Free time </span>
    <span class="small-heading gark-dark">1 hour, 25 mins</span>
  </div>
  <div class="clearfix"></div>
</div>
