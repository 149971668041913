import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-hub',
  templateUrl: './hub.component.html',
  styleUrls: ['./hub.component.scss'],
  standalone: true,
})
export class HubComponent implements OnInit {
  @Input() hub: any;
  constructor() {}
  duration: string = '';
  ngOnInit() {
    if (this.hub.hasOwnProperty('duration_hours')) {
      this.duration = this.hub.duration_hours;
    }
  }
}
