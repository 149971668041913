import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'reference-block',
  standalone: true,
  templateUrl: './reference-block.component.html',
  styleUrls: ['./reference-block.component.css'],
})
export class ReferenceBlockComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
