<div #imageDisplay>
  <picture *ngIf="!lazyLoad">
    <source
      srcset="{{imagePathWebp}}"
      type="image/webp"
      class="img-fluid"
      (loaded)="onLoaded()"
    />
    <img
      src="{{ imagePath }}"
      class="img-fluid rounded-6"
      (error)="onLoaded()"
    />
  </picture>
  <div *ngIf="lazyLoad">
    <picture>
      <source
        srcset="{{imagePathWebp}}"
        type="image/webp"
        class="img-fluid imgAnimate rounded-6"
        (loaded)="onLoaded()"
      />

      <img
        src="{{ imagePath }}"
        class="img-fluid imgAnimate rounded-6"
        [ngClass]="{ show: isLoaded }"
        *ngIf="isVisible"
        (load)="imageLoaded()"
        (error)="onLoaded()"
      />
    </picture>
  </div>
</div>
