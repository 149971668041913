import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'free-time',
  standalone: true,
  templateUrl: './free-time.component.html',
  styleUrls: ['./free-time.component.scss'],
})
export class FreeTimeComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
