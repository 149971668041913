<div class="d-flex">
  <div class="flex-10 align-self-stretch iconwrapper">
    <i class="fas fa-map-marker-alt loc_marker"></i>
  </div>
  <div class="flex-90 align-self-stretch">
    <div class="d-flex">
      <div class="flightgrid1">
        <div class="content-body mb-1 primary-text-color">
          <span>{{ hub["hub_name"] }}</span>
          <span class="primary-text-color">
            <i class="fa fa-chevron-down iconsize mr-2 arrowDisable"> </i>
            <i class="fa fa-chevron-up iconsize mr-2 arrowDisable"> </i>
          </span>
        </div>
        <!--         
        <div class="small-heading">
          <span>Start Date : </span>
          <span>28/10/2023</span>
        </div>
        <div class="small-heading">
          <span>Duration of stay : </span>
          <span>{{ duration }}</span>
        </div> -->
      </div>
    </div>
  </div>
</div>
