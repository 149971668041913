import { Component, Input, OnInit } from '@angular/core';
//import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-hub-mode',
  templateUrl: './hub-mode.component.html',
  styleUrls: ['./hub-mode.component.scss'],
  standalone: true,
})
export class HubModeComponent implements OnInit {
  @Input() mode: any;
  duration: string = '';
  commuteIcon: string = 'fa-slash';

  constructor() {}

  ngOnInit() {
    if (this.mode.hasOwnProperty('duration_hours')) {
      this.duration = this.mode.duration_hours + ' hrs';
    }

    const mode = this.mode.hasOwnProperty('mode') ? this.mode.mode : '';
    switch (mode) {
      case 'flight':
        this.commuteIcon = 'fa-plane-departure';
        break;
      case 'car':
        this.commuteIcon = 'fa-car';
        break;
      case 'train':
        this.commuteIcon = 'fa-train';
        break;
      case 'walk':
        this.commuteIcon = 'fa-walking';
        break;
      case 'tube':
        this.commuteIcon = 'fa-subway';
        break;
      default:
        this.commuteIcon = 'fa-slash';
    }
  }
}
